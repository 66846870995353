import { flow, makeAutoObservable } from 'mobx';
import gql from 'graphql-tag';
import { unifiedAlert } from '../core/utils/utils';
import { sub } from 'date-fns';
import { OUTBOUND_VALIDATION_SCHEMA } from '../components/CreateOutboundForm';

const OUTBOUND_ORDER_QUERY = gql`
  query outbounds(
    $warehouseAccountIds: [ID!]!
    $create_time_start: Float
    $consignmentNumbers: [ID!]
    $page_no: Int = 1
    $page_size: Int = 500
  ) {
    outbounds(
      warehouseAccountIds: $warehouseAccountIds
      create_time_start: $create_time_start
      consignmentNumbers: $consignmentNumbers
      page_no: $page_no
      page_size: $page_size
    ) {
      consignments {
        warehouseAccountId
        consignment_type
        consignment_no
        ref_no
        from_warehouse_code
        logistics_provider
        logistics_product_code
        shipping_no
        status
        total_volume
        total_weight
        billing_weight
        shipping_weight
        create_time
        complete_time
        remark
        first_name
        last_name
        email
        logistics_provider
        logistics_product_code
        signature_service
        country
        state
        city
        district
        post_code
        street
        house_number
        company
        shipmentOrderId
        pickingTaskType
        exceptionReason
        packingDetails
        outboundlist_sku {
          sku_id
          sku_code
          qty
        }
      }
    }
  }
`;

const COPY_OUTBOUNDS = gql`
  mutation copyOutboundOrder($outboundOrderIds: [String], $warehouseAccountId: ID!) {
    copyOutboundOrder(
      outboundOrderIds: $outboundOrderIds
      warehouseAccountId: $warehouseAccountId
    ) {
      consignment_no
      status
      exceptionReason
      createTransaction {
        amount
      }
    }
  }
`;

const CREATE_OUTBOUND = gql`
  mutation createOutboundOrder(
    $warehouseAccountId: ID!
    $refNo: String!
    $address: OutboundAddressInput!
    $skus: [SkuQtyInput!]!
    $note: String
    $courier: String!
    $warehouse: String!
    $sales_no: String
  ) {
    createOutboundOrder(
      warehouseAccountId: $warehouseAccountId
      ref_no: $refNo
      address: $address
      skus: $skus
      note: $note
      courier: $courier
      warehouse: $warehouse
      sales_no: $sales_no
    ) {
      consignment_no
      status
      exceptionReason
      createTransaction {
        amount
      }
    }
  }
`;

const CREATE_BATCH_OUTBOUNDS = gql`
  mutation createBatchOutboundOrders(
    $warehouseAccountId: ID!
    $outboundOrders: [OutboundOrderInput!]!
  ) {
    createBatchOutboundOrders(
      warehouseAccountId: $warehouseAccountId
      outboundOrders: $outboundOrders
    ) {
      consignment_no
      status
      exceptionReason
      createTransaction {
        amount
      }
    }
  }
`;

const UPDATE_OUTBOUND = gql`
  mutation updateOutboundOrder(
    $warehouseAccountId: ID!
    $consignment_no: ID!
    $refNo: String!
    $address: OutboundAddressInput!
    $skus: [SkuQtyInput!]!
    $note: String
    $courier: String!
    $warehouse: String!
    $sales_no: String
  ) {
    updateOutboundOrder(
      warehouseAccountId: $warehouseAccountId
      consignment_no: $consignment_no
      ref_no: $refNo
      address: $address
      skus: $skus
      note: $note
      courier: $courier
      warehouse: $warehouse
      sales_no: $sales_no
    ) {
      consignment_no
      status
      exceptionReason
      createTransaction {
        amount
      }
    }
  }
`;

const CANCEL_OUTBOUND = gql`
  mutation cancelOutboundOrder($warehouseAccountId: ID!, $consignment_no: ID!) {
    cancelOutboundOrder(warehouseAccountId: $warehouseAccountId, consignment_no: $consignment_no) {
      warehouseAccountId
      consignment_no
    }
  }
`;

const OUTBOUND = gql`
  query outbound($warehouseAccountId: ID!, $consignmentNumber: ID!) {
    outbound(warehouseAccountId: $warehouseAccountId, consignmentNumber: $consignmentNumber) {
      warehouseAccountId
      consignment_type
      consignment_no
      ref_no
      sales_no
      from_warehouse_code
      logistics_provider
      logistics_product_code
      sales_platform
      shipping_no
      status
      total_volume
      total_weight
      billing_weight
      shipping_weight
      create_time
      complete_time
      remark
      email
      last_name
      first_name
      phone
      insure_services
      insure_value
      logistics_provider
      logistics_product_code
      signature_service
      country
      state
      city
      district
      post_code
      street
      house_number
      company
      shipmentOrderId
      pickingTaskType
      exceptionReason
      packingDetails
      outboundlist_sku {
        sku_id
        sku_name
        sku_code
        qty
      }
      transactions {
        id
        amount
        remark
        remarkExtra
        status
      }
    }
  }
`;

const mapOutboundConsignment = (oc) => {
  const skuKeys = Object.keys(oc).filter((key) => key.startsWith('SKU') && !!oc[key]);
  const skus = skuKeys.map((key) => {
    const index = key.replace('SKU', '');
    const qtyLabel = `QTY${index}`;
    return {
      sku: oc[key],
      qty: parseInt(oc[qtyLabel]),
    };
  });
  return {
    refNo: oc['Ref Code'] && `${oc['Ref Code']}`,
    address: {
      country: oc['Country'],
      state: oc['State'],
      city: oc['City'],
      district: oc['District'],
      post_code: oc['Postcode'] && `${oc['Postcode']}`,
      street: oc['Street Name'],
      house_number: oc['House Number'] && `${oc['House Number']}`,
      company: oc['Company'],
      name: oc['Name'] && `${oc['Name']}`,
      phone: oc['Phone Number'] && `${oc['Phone Number']}`,
      email: oc['Email'],
    },
    skus,
    note: oc['Note'],
    courier: oc['Courier'],
    warehouse: oc['Warehouse Code'],
    sales_no: oc['Sales Number'] && `${oc['Sales Number']}`,
  };
};

export class OutboundOrderStore {
  cachedItems = null;
  loading = true;
  error = null;

  constructor(client, warehouseAccountStore) {
    this.client = client;
    this.warehouseAccountStore = warehouseAccountStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        addItem: flow,
        addItems: flow,
        updateItem: flow,
        client: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }

  *fetchItems() {
    this.loading = true;
    this.cachedItems = null;
    try {
      const { data } = yield this.client.query({
        query: OUTBOUND_ORDER_QUERY,
        // upper bound query period to 30 days, otherwise it might crash server
        variables: {
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
          create_time_start: sub(Date.now(), { days: 29 }).getTime(),
        },
        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
      });
      this.cachedItems = data.outbounds.consignments;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  *addItem(warehouseAccountId, outbound) {
    this.loading = true;
    const {
      address: { houseNumber = '', ...remainAddress },
      ...restOutbound
    } = outbound;

    try {
      const { data } = yield this.client.mutate({
        mutation: CREATE_OUTBOUND,
        variables: {
          ...restOutbound,
          address: { ...remainAddress, house_number: houseNumber },
          warehouseAccountId,
        },
      });
      return data.createOutboundOrder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *copyItems(outboundOrderIds, warehouseAccountId) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: COPY_OUTBOUNDS,
        variables: {
          outboundOrderIds,
          warehouseAccountId,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *updateItem(warehouseAccountId, outbound) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: UPDATE_OUTBOUND,
        variables: {
          ...outbound,
          warehouseAccountId,
        },
      });
      return data.updateOutboundOrder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *cancelItem(warehouseAccountId, consignment_no) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: CANCEL_OUTBOUND,
        variables: {
          warehouseAccountId,
          consignment_no,
        },
      });
      return data.cancelOutboundOrder;
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *getOutboundOrder(warehouseAccountId, consignmentNumber) {
    try {
      const { data } = yield this.client.query({
        query: OUTBOUND,
        variables: {
          warehouseAccountId,
          consignmentNumber,
        },
        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
      });
      return data.outbound;
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    }
  }

  *getOutboundOrders(warehouseAccountIds, consignmentNumbers) {
    try {
      const { data } = yield this.client.query({
        query: OUTBOUND_ORDER_QUERY,
        variables: {
          warehouseAccountIds,
          consignmentNumbers,
          page_no: 1,
          page_size: consignmentNumbers.length,
        },
        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
      });
      return data.outbounds?.consignments ?? [];
    } catch (error) {
      console.error(error);
      const msg = 'Error! ' + JSON.stringify(error.message || error);
      unifiedAlert(msg);
      throw error;
    }
  }

  static MapCsvUploadedOutboundConsignments(data) {
    return data.map(mapOutboundConsignment);
  }

  static async ValidateCsvUploadedOutboundConsignments(data) {
    const errors = {};
    await Promise.all(
      data.map((oc, i) =>
        OUTBOUND_VALIDATION_SCHEMA.validate(oc, {
          abortEarly: false,
          context: true,
          strict: true,
        }).catch((error) => {
          errors[i] = error;
        }),
      ),
    );
    return errors;
  }

  *addItems(warehouseAccountId, items) {
    const MAX_BATCH_SIZE = 20;
    let ret = [];
    let errors = '';

    for (let i = 0; i < items.length; i += MAX_BATCH_SIZE) {
      const batch = items.slice(i, i + MAX_BATCH_SIZE);
      try {
        const { data } = yield this.client.mutate({
          mutation: CREATE_BATCH_OUTBOUNDS,
          variables: {
            outboundOrders: batch,
            warehouseAccountId,
          },
        });
        ret = ret.concat(data.createBatchOutboundOrders);
      } catch (e) {
        errors += e.message;
        console.error(e);
      }
    }

    if (errors.length > 0) {
      throw new Error(errors);
    }
    return ret;
  }
}
