import { gql, useQuery } from '@apollo/client';
import { Icon, Button as UIButton } from '@ui-kitten/components';
import { format, setMonth, setYear } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Card as RNPCard } from 'react-native-paper';
import { printBase64Pdf, useIsAccountManager } from '../core/utils/utils';
import { xeroStore } from '../store';
import Button from './Button';
import Select from './Select';
import Table from './Table';

const IS_ORG_ADMIN = gql`
  query isOrgAdmin {
    isOrgAdmin
  }
`;

export const ListInvoice = ({ organisationId, showLogOutButton = true }) => {
  const { t } = useTranslation();
  const isAccountManager = useIsAccountManager();

  const { data: isOrgAdminRes } = useQuery(IS_ORG_ADMIN);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [logged, setLogged] = useState(new Date().getFullYear());
  const [earliestTransationDate, setEarliestTransationDate] = useState(null);

  const startTime = earliestTransationDate && new Date(parseInt(earliestTransationDate));

  const handleDownload = async (month, year) => {
    if (!logged) return;

    const data = await xeroStore.getInvoiceAsPdf(month, year, organisationId);

    if (data) return printBase64Pdf(data);
  };

  useEffect(async () => {
    const time = await xeroStore.getEarliestTransactionTime(organisationId);
    setEarliestTransationDate(time);

    const isLogged = await xeroStore.isLoggedIn;
    setLogged(isLogged);
  }, []);

  return (
    <>
      {((isOrgAdminRes?.isOrgAdmin && logged) || isAccountManager) && (
        <RNPCard style={styles.card}>
          <RNPCard.Title title={t('components.invoice.title')} />
          <RNPCard.Content>
            <React.Fragment>
              {!logged && isAccountManager && (
                <Button
                  mode="contained"
                  onPress={async () => {
                    const data = await xeroStore.getXeroUrlAuth();
                    if (data) {
                      window.location.replace(data);
                    }
                  }}>
                  {t('components.invoice.connect')}
                </Button>
              )}

              {logged && (
                <>
                  <View>
                    {startTime && (
                      <Select
                        style={{ maxWidth: 400 }}
                        placeholder={t('components.invoice.selectYear')}
                        options={
                          startTime
                            ? Array.from({
                                length: new Date().getFullYear() - startTime.getFullYear() + 1,
                              }).map((_, i) => new Date().getFullYear() - i)
                            : [new Date().getFullYear()]
                        }
                        value={selectedYear}
                        onChange={(val) => {
                          setSelectedYear(val);
                        }}
                      />
                    )}
                  </View>
                  <Table
                    emptyDataMsg={t('components.invoice.noData')}
                    displayKeys={['ref', 'date', 'action']}
                    items={getMonthYearArray(selectedYear, startTime) || []}
                    titleByKey={{
                      date: t('components.invoice.table.date'),
                      action: t('components.invoice.table.action'),
                      ref: t('components.invoice.table.ref'),
                    }}
                    formatterByKey={{
                      ref: (_, item) => {
                        return <Text>{item.month}</Text>;
                      },
                      date: (_, item) => (
                        <Text>
                          {format(
                            setMonth(setYear(new Date(), item.year), item.month - 1),
                            'MMMM yyyy',
                          )}
                        </Text>
                      ),
                      action: (_, item) => (
                        <UIButton
                          appearance="ghost"
                          size="medium"
                          accessoryLeft={(props) => <Icon name="printer" {...props} />}
                          onPress={() => handleDownload(item.month, item.year)}
                        />
                      ),
                    }}
                  />
                  {isAccountManager && showLogOutButton && (
                    <Button
                      mode="outlined"
                      onPress={async () => {
                        await xeroStore.logOut();
                        setLogged(false);
                      }}>
                      {t('components.invoice.disconnect')}
                    </Button>
                  )}
                </>
              )}
            </React.Fragment>
          </RNPCard.Content>
        </RNPCard>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    marginVertical: '1em',
    border: '1px solid #F2F2F2',
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
});
const getMonthYearArray = (year, startTime) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  let fromTime = startTime ?? new Date();

  const monthLimit = year === currentYear ? currentMonth + 1 : 12;

  const result = Array.from({ length: monthLimit }, (_, i) => {
    if (year === fromTime.getFullYear() && i >= fromTime.getMonth()) {
      return { month: i + 1, year: fromTime.getFullYear() };
    } else if (year !== fromTime.getFullYear()) {
      return { month: i + 1, year: year };
    }
  });

  return result.filter((time) => time);
};
