import { AU_POSTCODE_LENGTH, SHIPPING_CHANNEL_CODE } from '@ezom/library/lib/cjs/constants';
import * as Yup from 'yup';

// checks if export info is required for the chose courier
const exportInfoTester = (courierList) => (value, testContext) => {
  const courierConfig = courierList.find(
    (courier) => courier.logistics_product_code === testContext.from[2]?.value?.courier,
  );
  if (courierConfig?.requireExportInfo) {
    return !!value;
  }
  return true;
};
export const getShipmentValidationSchema = (courierList) =>
  Yup.object({
    ref_no: Yup.string().required('Required').max(24),
    sales_no: Yup.string(),
    note: Yup.string().nullable().max(250),
    shipment: Yup.object({
      courier: Yup.string().required('Required'),
      serviceCode: Yup.string().when('courier', {
        is: SHIPPING_CHANNEL_CODE.BONDS,
        then: Yup.string().required('Required'),
      }),
      vehicleType: Yup.string().when('courier', {
        is: SHIPPING_CHANNEL_CODE.BONDS,
        then: Yup.string().required('Required'),
      }),
      deliveryWindowEnabled: Yup.boolean().required(),
      deliveryWindowStart: Yup.string().when('deliveryWindowEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      deliveryWindowEnd: Yup.string().when('deliveryWindowEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      dangerousGoods: Yup.boolean().required(),
      handUnload: Yup.boolean().required(),
      tailgate: Yup.boolean().required(),
      items: Yup.array()
        .min(1)
        .of(
          Yup.object({
            description: Yup.string().required('Required'),
            commodity: Yup.string(),
            qty: Yup.number().integer('Integer only').min(1, 'Too small').required('Required'),
            weight: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            length: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            width: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            height: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            exportInfo: Yup.array().of(
              Yup.object({
                declare: Yup.string().test('declare', 'Required', exportInfoTester(courierList)),
                hscode: Yup.string().test(
                  'declareHscode',
                  'Required',
                  exportInfoTester(courierList),
                ),
                qty: Yup.number()
                  .integer('Integer only')
                  .min(1, 'Too small')
                  .max(Number.MAX_SAFE_INTEGER, 'Too large')
                  .test('declareQty', 'Required', exportInfoTester),
                declareValue: Yup.number()
                  .min(0, 'Too small')
                  .max(Number.MAX_SAFE_INTEGER, 'Too large')
                  .test('declareValue', 'Required', exportInfoTester),
                countryOfOrigin: Yup.string()
                  .length(2, 'Country of origin must be a 2-character code')
                  .test('countryOfOrigin', 'Required', exportInfoTester),
                weight: Yup.number()
                  .min(0.01, 'Too small')
                  .max(Number.MAX_SAFE_INTEGER, 'Too large')
                  .test('declareWeight', 'Required', exportInfoTester),
              }),
            ),
          }),
        ),
    }).required(),
    pickupAddress: Yup.object({
      country: Yup.string().required().max(50),
      name: Yup.string().required().max(35),
      state: Yup.string().required().max(50),
      city: Yup.string().required().max(50),
      phone: Yup.string().max(20),
      email: Yup.string().email('Not a valid email address').max(50),
      house_number: Yup.string().nullable().max(20),
      post_code: Yup.string()
        .required()
        .when('country', (country, schema) => {
          if (country === 'AU') {
            return schema.length(
              AU_POSTCODE_LENGTH,
              `In ${country}, the postcode must be ${AU_POSTCODE_LENGTH} characters long`,
            );
          }
          return schema.max(12);
        }),
      company: Yup.string().nullable().max(30),
      street: Yup.string().required().max(50),
    }),
    receiverAddress: Yup.object({
      country: Yup.string().required().max(50),
      name: Yup.string().required().max(35),
      state: Yup.string().required().max(50),
      city: Yup.string().required().max(50),
      phone: Yup.string().max(20),
      email: Yup.string().email('Not a valid email address').max(50),
      house_number: Yup.string().nullable().max(20),
      post_code: Yup.string()
        .required()
        .when('country', (country, schema) => {
          if (country === 'AU') {
            return schema.length(
              AU_POSTCODE_LENGTH,
              `In ${country}, the postcode must be ${AU_POSTCODE_LENGTH} characters long`,
            );
          }
          return schema.max(12);
        }),
      company: Yup.string().nullable().max(30),
      street: Yup.string().required().max(50),
    }),
  });
